// ==================================================
// PICK UP出力
// ==================================================
/**
 * 文字をカットする。
 * @param  {String} text カットしたい文字
 * @param  {Number} n    何文字以降でカットするか
 * @return {String}      カット後の文字列
 */
function cutString(text, n) {
  if (text.length > n) {
    text = text.substr(0, n);
    text += '...';
  }
  return text;
}

$(function () {
  /**
   * みのりグループTOPに公開する条件　2：有（紹介中）の物件のみ
   */
  var SITUATION_IN_PUBLIC = '2';

  CommonLib.search_article(0, 3, null, null, null, null, null, null, SITUATION_IN_PUBLIC, 1, "1", function (data) {
    var articles = data.result.articles;

    for (var i = 0; i < articles.length; i++) {
      var article = articles[i];
      var imgPath = (article.image) ?
        article.image.url :
        '/shokai/assets/img/cmn/noimage.jpg';
      var target = location.protocol + '//' + location.host + '/shokai/search/detail/?id=' + article.id;
      var $template = $('#js-pickup-template').find('.c-row_item').clone(true);

      var
        $anchor = $template.find('.p-pickup_target'),
        $img = $template.find('.p-pickup_img > div'),
        $label = $template.find('.p-pickup_label'),
        $title = $template.find('.p-pickup_ttl'),
        $text = $template.find('.p-pickup_txt');

      var address = [
        article.address_city !== null ? article.address_city : '',
        article.address_street !== null ? article.address_street : ''
      ];
      $anchor.attr('href', target);
      $img.css('background-image', 'url(' + imgPath + ')');
      $label.addClass('p-pickup_label-' + article.type_kind_2);
      $title.html(cutString((address.join('')), 11));
      $text.html(cutString(article.pr_memo ? article.pr_memo : '', 47));
      $('#js-pickup').append($template);
    }

  });
});


// ------------------------------------------------------------
// swiper
// ------------------------------------------------------------
$(function () {
  var visual_swiper = new Swiper('.visual_swiper-container', {
    pagination: '.visual_swiper-pagination',
    paginationClickable: true,
    loop: true,
    autoplay: 5000,
    autoplayDisableOnInteraction: false,
    speed: 2000,
    effect: 'fade',
    fade: {
      crossFade: true
    }
  });
});

// ------------------------------------------------------------
// 新築住宅、不動産売買・貸借、不動産コンサルティングのところ
// ------------------------------------------------------------
/**
 * 3つリンクがある箇所
 * 
 * @class Home_three_column
 */
class Home_three_column {
  /**
   * Creates an instance of Home_three_column.
   * @param {Number} mw 基準となるコンテンツ幅
   * @memberof Home_three_column
   */
  constructor(mw) {
    this.maxWidth = mw;
    this.oneColumnWidth = Math.floor(this.maxWidth / 3);
    this.targets = [
      '.p-intro_item-01',
      '.p-intro_item-02',
      '.p-intro_item-03',
    ];
  }
  /**
   * リサイズ処理
   * 
   * @memberof Home_three_column
   */
  resizing() {
    if (isWide) {
      if (get_window_width() > this.maxWidth) {
        var diff = get_window_width() - get_scrollbar_width() - this.maxWidth;
        diff /= 2;
        $(this.targets[1]).add($('.p-intro_content')).css('width', this.oneColumnWidth);
        $(this.targets[0]).add($(this.targets[2])).css('width', diff + this.oneColumnWidth);
      } else {
        $('.p-intro_item, .p-intro_content').css('width', this.oneColumnWidth);
      }
    } else {
      $('.p-intro_item, .p-intro_content').css('width', '');
    }
  }
  /**
   * mouseover,
   * mouseout
   * 
   * @memberof Home_three_column
   */
  mouse_move() {
    var classes = {
      btn:     '.p-intro_btn > .c-btn',
      item:    '.p-intro_item',
      overlay: '.p-intro_overlay',
    };
    $('body').on('mouseover', classes.btn, function () {
      if (isWide) {
        var $oneColumn = $(this).parents(classes.item);
        var $target = $oneColumn.children(classes.overlay);
        $target.stop().fadeOut();
      }
    }).on('mouseout', classes.btn, function () {
      if (isWide) {
        var $oneColumn = $(this).parents(classes.item);
        var $target = $oneColumn.children(classes.overlay);
        $target.stop().fadeIn();
      }
    });
  }
}
$(function () {
  var obj = new Home_three_column(1150);
  obj.resizing();
  obj.mouse_move();
  $(window).on('resize', function () {
    obj.resizing();
  });
});
